import { mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import MarkdownContent from './MarkdownContent';

const FormCard = ({
  name, title, fieldLabels, actionTitle, dataProtectionLabel, contentComponent
}) => {
  const homeFormDataObject = {
    name: '', email: '', phone: '', message: ''
  };
  const jobsFormDataObject = {
    name: '', email: '', phone: '', apprenticeship: '', salary: '', message: ''
  };

  const [formData, setFormData] = useState(name === 'jobs' ? jobsFormDataObject : homeFormDataObject);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const encode = (data) => Object.keys(data)
    .map((key) => `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`)
    .join('&');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': name, ...formData })
    })
      .then(() => setShowSuccessMessage(true))
      .catch((error) => alert(error));

    setFormData(name === 'jobs' ? jobsFormDataObject : homeFormDataObject);
  };

  const MarkdownComponent = contentComponent || MarkdownContent;

  return (
    <Card className="px-sm-1 px-md-2 px-lg-3 px-xl-4 px-xxl-6 py-3">
      <Card.Body>
        <Card.Title className={ showSuccessMessage ? 'invisible' : null }>{title}</Card.Title>
        <Form className={ showSuccessMessage ? 'invisible' : null } name={ name } onSubmit={ handleSubmit } data-netlify="true" data-netlify-honeypot="bot-field">
          <Form.Control type="hidden" name="form-name" value={ name } />
          { name === 'home' && (
          <>
            <Form.Group controlId="name">
              <Form.Label>{fieldLabels && fieldLabels[0]} *</Form.Label>
              <Form.Control type="text" name="name" defaultValue={ formData.name } onChange={ handleChange } required />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>{fieldLabels && fieldLabels[1]} *</Form.Label>
              <Form.Control type="email" name="email" defaultValue={ formData.email } onChange={ handleChange } required />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>{fieldLabels && fieldLabels[2]}</Form.Label>
              <Form.Control type="phone" name="phone" defaultValue={ formData.phone } onChange={ handleChange } />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>{fieldLabels && fieldLabels[3]} *</Form.Label>
              <Form.Control as="textarea" rows="4" name="message" defaultValue={ formData.message } onChange={ handleChange } required />
            </Form.Group>
          </>
          )}
          { name === 'jobs' && (
          <>
            <Form.Group controlId="name">
              <Form.Label>{fieldLabels && fieldLabels[0]} *</Form.Label>
              <Form.Control type="text" name="name" defaultValue={ formData.name } onChange={ handleChange } required />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>{fieldLabels && fieldLabels[1]} *</Form.Label>
              <Form.Control type="email" name="email" defaultValue={ formData.email } onChange={ handleChange } required />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>{fieldLabels && fieldLabels[2]} *</Form.Label>
              <Form.Control type="phone" name="phone" defaultValue={ formData.phone } onChange={ handleChange } required />
            </Form.Group>
            <Form.Group controlId="apprenticeship">
              <Form.Label>{fieldLabels && fieldLabels[3]} *</Form.Label>
              <Form.Control type="text" name="apprenticeship" defaultValue={ formData.apprenticeship } onChange={ handleChange } required />
            </Form.Group>
            <Form.Group controlId="salary">
              <Form.Label>{fieldLabels && fieldLabels[4]}</Form.Label>
              <Form.Control type="text" name="salary" defaultValue={ formData.salary } onChange={ handleChange } />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>{fieldLabels && fieldLabels[5]} *</Form.Label>
              <Form.Control as="textarea" rows="4" name="message" defaultValue={ formData.message } onChange={ handleChange } required />
            </Form.Group>
          </>
          )}
          <Form.Group controlId="dataProtection">
            <Row noGutters>
              <Col xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 } xl={ 1 }>
                <Form.Check type="checkbox" inline required />
              </Col>
              <Col xs={ 11 } sm={ 11 } md={ 11 } lg={ 11 } xl={ 11 }>
                <MarkdownComponent containerClasses="d-inline" content={ dataProtectionLabel } />
              </Col>
            </Row>
          </Form.Group>
          <Button variant="primary" type="submit" className="float-right">{actionTitle}</Button>
        </Form>
        { showSuccessMessage
          && (
          <Container
            className="position-absolute d-flex align-items-center"
            style={{
              top: 0, left: 0, bottom: 0, right: 0
            }}
          >
            <Container>
              <Row className="justify-content-center">
                <Col xs={ 6 } sm={ 6 } md={ 6 } lg={ 4 } xl={ 4 }>
                  <Icon path={ mdiCheckCircle } className="text-success" />
                </Col>
              </Row>
              <Row className="justify-content-center my-4">
                <Col xs={ 12 } sm={ 12 } md={ 10 } lg={ 8 } xl={ 8 }>
                  <h3 className="text-center">Vielen Dank für das Interesse!</h3>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={ 12 } sm={ 12 } md={ 10 } lg={ 8 } xl={ 8 }>
                  <p className="text-center">{name === 'jobs' ? 'Deine' : 'Ihre'} Nachricht ist bei uns eingegangen. Wir werden uns zeitnah bei {name === 'jobs' ? 'Dir' : 'Ihnen'} melden.</p>
                </Col>
              </Row>
            </Container>
          </Container>
          )}
      </Card.Body>
    </Card>
  );
};

export default FormCard;

FormCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fieldLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  actionTitle: PropTypes.string.isRequired,
  dataProtectionLabel: PropTypes.node.isRequired,
  contentComponent: PropTypes.func
};

FormCard.defaultProps = {
  contentComponent: null
};