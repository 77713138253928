import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import FormCard from '../components/FormCard';
import PreviewCompatibleBackgroundImage from '../components/PreviewCompatibleBackgroundImage';

const FormCardSection = ({
  name, title, fieldLabels, dataProtectionLabel, actionTitle, backgroundImage, contentComponent
}) => (
  <PreviewCompatibleBackgroundImage
    imageInfo={{ image: backgroundImage }}
    imageClasses="py-5 my-0"
    imageStyle={{
      backgroundColor: 'rgba(0,0,0,0.4)',
      backgroundBlendMode: 'overlay'
    }}
  >
    <Container>
      <Row className="justify-content-center">
        <Col xs={ 12 } sm={ 12 } md={ 8 } lg={ 7 } xl={ 6 } className="col-xxl-5">
          <FormCard
            name={ name }
            title={ title }
            fieldLabels={ fieldLabels }
            actionTitle={ actionTitle }
            dataProtectionLabel={ dataProtectionLabel }
            contentComponent={ contentComponent }
          />
        </Col>
      </Row>
    </Container>
  </PreviewCompatibleBackgroundImage>
);

FormCardSection.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fieldLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  actionTitle: PropTypes.string.isRequired,
  backgroundImage: PropTypes.objectOf(PropTypes.object).isRequired,
  dataProtectionLabel: PropTypes.node.isRequired,
  contentComponent: PropTypes.func
};

FormCardSection.defaultProps = {
  contentComponent: null
};

export default FormCardSection;