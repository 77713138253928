import { mdiFilePdf } from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import remark from 'remark';
import remarkHtml from 'remark-html';

import { HTMLContent } from '../components/MarkdownContent';

const TextSection = ({
  title, description, textDirection, sectionClassName, lineDecorationClassName, titleColorClassName, hasAction, actionText, actionRoute, pdfFiles
}) => {
  const desciptionAsHtml = remark().use(remarkHtml).processSync(description).toString();
  return (
    <Container className={ `${sectionClassName} py-5 my-0` } fluid>
      <Container>
        <Row className="justify-content-between" noGutters>
          <Col
            xs={ 12 }
            className={
              `${textDirection === 'left'
                ? 'order-sm-0 order-md-0 order-lg-0 order-xl-0 order-xxl-0'
                : 'order-sm-0 order-md-0 order-lg-1 order-xl-1 order-xxl-1'
              } line-decoration ${ lineDecorationClassName } pt-1 my-4`
            }
          >
            <h3 className={ `text-break ${ titleColorClassName}` }>{title}</h3>
            <HTMLContent className="mt-5" containerClasses="d-inline" content={ desciptionAsHtml } />
            {hasAction && actionText !== ''
              && <Link to={ actionRoute } className="btn btn-primary">{actionText}</Link>}
            { pdfFiles && pdfFiles.filter((file) => file.source || file.downloadText).map((file, i) => (
              <a href={ file.source && file.source.publicURL ? file.source.publicURL : '' } target="_blank" rel="noreferrer" className="d-flex" key={ i }>
                <Icon path={ mdiFilePdf } size={ 1 } /><span>{ file.downloadText }</span>
              </a>
            ))}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

TextSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textDirection: PropTypes.string,
  sectionClassName: PropTypes.string,
  lineDecorationClassName: PropTypes.string,
  titleColorClassName: PropTypes.string,
  hasAction: PropTypes.bool,
  actionRoute: PropTypes.string,
  actionText: PropTypes.string,
  pdfFiles: PropTypes.arrayOf(PropTypes.shape({
    source: PropTypes.object,
    downloadText: PropTypes.string
  })),
};

TextSection.defaultProps = {
  textDirection: 'left',
  sectionClassName: '',
  lineDecorationClassName: 'line-decoration-dark',
  titleColorClassName: '',
  hasAction: false,
  actionRoute: '/',
  actionText: '',
  pdfFiles: [],
};

export default TextSection;