import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Layout from '../components/Layout';
import { HTMLContent } from '../components/MarkdownContent';
import FormCardSection from '../sections/FormCardSection';
import HeroSection from '../sections/HeroSection';
import IntroWithCountersSection from '../sections/IntroWithCountersSection';
import TextSection from '../sections/TextSection';

export const JobsPageTemplate = ({
  image,
  title,
  description,
  jobs,
  form,
  html,
  contentComponent
}) => (
  <div className="flex-grow-1">
    <HeroSection image={ image } heightStyle="75vh" />
    <IntroWithCountersSection
      title={ title }
      description={ description }
      bgColorClassName="bg-primary"
      fontColorClassName="text-white"
      lineDecorationClassName="line-decoration-white"
    />
    { jobs && jobs.map((job) => (
      <Container>
        <Row className="justify-content-center" noGutters>
          <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 10 } xl={ 6 }>
            <TextSection
              title={ job.title }
              description={ job.description }
              key={ job.title }
              lineDecorationClassName="line-decoration-primary"
              titleColorClassName="text-primary"
            />
          </Col>
        </Row>
      </Container>
    ))}
    <FormCardSection
      name="jobs"
      title={ form.title }
      fieldLabels={ form.fieldLabels }
      dataProtectionLabel={ html }
      actionTitle={ form.actionTitle }
      backgroundImage={ form.backgroundImage }
      contentComponent={ contentComponent }
    />
  </div>
);

JobsPageTemplate.propTypes = {
  image: PropTypes.objectOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })),
  form: PropTypes.shape({
    title: PropTypes.string,
    fieldLabels: PropTypes.arrayOf(PropTypes.string),
    actionTitle: PropTypes.string,
    backgroundImage: PropTypes.objectOf(PropTypes.object)
  }).isRequired,
  html: PropTypes.node.isRequired,
  contentComponent: PropTypes.func
};

JobsPageTemplate.defaultProps = {
  contentComponent: null,
  jobs: []
};

const JobsPage = ({ data }) => {
  const { frontmatter, html, fields } = data.markdownRemark;
  return (
    <Layout
      title={ frontmatter.title }
      description={ frontmatter.description }
      slug={ fields.slug }
      language={ frontmatter.language }
    >
      <JobsPageTemplate
        image={ frontmatter.image }
        title={ frontmatter.title }
        description={ frontmatter.description }
        jobs={ frontmatter.jobs }
        form={ frontmatter.form }
        html={ html }
        contentComponent={ HTMLContent }
      />
    </Layout>
  );
};

export default JobsPage;

JobsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.node,
      fields: PropTypes.object
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query JobsByID($id: String!, $language: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { templateKey: {eq: "jobs-page" }, language: { eq: $language } }) {
      fields {
        slug
      }
      html
      frontmatter {
        language
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        jobs {
          title
          description
        }
        form {
          title
          fieldLabels
          actionTitle
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;